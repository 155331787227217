<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose"
    v-loading="loading"
    :showClose="showclose"
  >
    <div class="dialog_container">
      <!-- 左边详情 -->
      <div class="dialog_container_left">
        <div class="dialog_container_left_top">
          <img
            :src="imgsrc" alt=""
          />
          <div class="customName">{{ data.customerName }}</div>
          <div class="vipCode">会员号：{{ data.vipCode }}</div>
        </div>
        <p>权益类别:&emsp;{{ data.rightsName }}</p>
        <p>客户电话:&emsp;{{ data.tel1 }}</p>
        <p>机构:&emsp;{{ data.companyName }}</p>
        <p>申请时间:&emsp;{{ data.applyTime }}</p>
        <p>剩余配额:&emsp;{{ data.total }}</p>
      </div>
      <!-- 右边状态 -->
      <div class="dialog_container_right">
        <el-card style="display: flex; align-items: center; margin-bottom: 20px">
          <span style="width: 80px; margin-right: 20px">进度更新:</span>
          <el-select v-model="selectdate" placeholder="请选择任务状态" :disabled="option.length <= 1">
            <el-option
              :label="obj.name"
              :value="obj.val"
              v-for="(obj, i) in option ? option : optionlist"
              :key="i"
            ></el-option>
          </el-select>
        </el-card>
        <el-card style="flex: 1">
          <p>历史进度:</p>
          <!-- <el-steps align-center>
            <el-step
              :title="obj.processName"
              :description="obj.operateTime"
              v-for="obj in historylist"
              :key="obj.id"
            ></el-step>
          </el-steps> -->
          <div style="margin-left: 80px">
            <div class="line"></div>
            <div class="outBox" v-for="(item, index) in historylist" :key="index">
              <div class="word">
                <div id="outer_point" :style="outerPoint(index)">
                  <div id="point" :style="point(index)"></div>
                </div>
                &emsp;&emsp; <span class="word_time">{{ item.operateTime }}</span
                >&emsp;&emsp;
                <span class="word_processName">{{ item.processName }}</span>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" class="hovercancel">取 消</el-button>
      <el-button type="primary" @click="dialogVisibles" class="hoverdefine">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  props: ['dates'],
  data() {
    return {
      dialogVisible: true,
      type: 1,
      data: '',
      datatime: '',
      optionlist: [
        { name: '已预约', val: '102' },
        { name: '已完成', val: '103' },
        { name: '已取消', val: '201' },
      ],
      option: '', //动态任务栏数据
      selectdate: '',
      loading: true,
      historylist: '', //动态历史记录
      showclose: false,
      outerPoints: [
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
      ],
      points: [
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
      ],
      imgsrc:""
    }
  },
  created() {
    this.loaddata()
    // 获取动态下拉状态任务栏
    this.optionlist.map((objs, items) => {
      if (objs.name == this.dates.statusName) {
        this.option = this.optionlist.slice(items + 1)
        return
      }
    })
    this.option = this.option ? this.option : this.optionlist
  },

  methods: {
    // 获取页面初始数据
    loaddata() {
      postRequestAnthor('/rightoutside/queryRightsOutsideInfo', { id: this.dates.id })
        .then(res => {
          console.log(res)
          this.data = res
          this.imgsrc = res.customPicUrl?res.customPicUrl:"https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png"
          this.selectdate = this.data.statusName
          this.historylist = this.data.rightsOutsideHistoryDTOList.reverse()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.$emit('closeMailTask')
    },
    changeType(val) {
      this.type = val
    },
    outerPoint(i) {
      return this.outerPoints[i]
    },
    point(i) {
      return this.points[i]
    },
    // 确定修改
    dialogVisibles() {
      if (this.selectdate) {
        // 发送确认请求
        postRequestAnthor('/rightoutside/updateStatus', {
          id: this.dates.id,
          oldStatus: this.data.status,
          status: this.selectdate,
          updateBy: '1',
        }).then(() => {
          this.loaddata()
          this.$message({
            message: '信息修改成功',
            type: 'success',
          })
          this.dialogVisible = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog_container {
  margin-top:-6px;
  display: flex;
  //   右边状态
  .dialog_container_right {
    // border-radius: 5px;
    // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 11%) !important;
    margin-top: -30px;
    // background-color: #fff;
    flex: 6.6;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    // padding: 20px;
  }
  //   左边详情
  .dialog_container_left {
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 11%) !important;
    padding: 20px;
    background-color: #fff;
    flex: 3.4;
    display: flex;
    flex-direction: column;
    margin: -30px 20px 0 0;
    p {
      display: flex;
      align-items: center;
      margin: 12px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #676767;
      span {
        display: inline-block;
        width: 100px;
      }
    }
    .dialog_container_left_top {
      text-align: center;
      margin: 0;
      // padding: 14px;
      border-bottom: 1px solid #e5e5e5;
      div {
        text-align: center;
      }
      .customName {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #323232;
        margin-top: 16px;
      }
      .vipCode {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #676767;
        margin: 10px 0 16px;
      }
    }
  }
}
img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.button {
  margin-bottom: 20px;
}
.el-select {
  width: 100% !important;
}
.outBox {
  margin: 0 auto;
}
.word {
  display: flex;
  align-items: center;
  margin: 0 auto;
  #outer_point {
    margin-left: -2.5px;

    border-radius: 50%;
  }
  #point {
    width: 4px;
    height: 4px;
    margin: 1px;

    border-radius: 50%;
  }
  .word_time {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .word_processName {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
  }
}
.line {
  width: 1px;
  height: 25px;
  background: #e6e6e6;
  margin: -5px 0 -5px 1px;
}
.dialog-footer {
  margin-top: -20px;
  display: flex;
  justify-content: center;
}
.el-select {
  width: 70% !important;
}
</style>