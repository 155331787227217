<template>
  <!-- 外部权益处理 -->
  <div class="organ-management-page" v-loading="loading">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:" style="margin-right: -20px">
              <el-input v-model="form.customerName" :clearable="true" placeholder="请输入客户名称"></el-input>
            </el-form-item>
            <el-form-item label="客户电话:" style="margin-right: -20px">
              <el-input
                v-model="form.customerPhone"
                :clearable="true"
                placeholder="请输入客户电话"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="申请时间:" style="margin-right: -30px">
              <el-date-picker v-model="form.date1" type="date" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
            <el-form-item label="权益:">
              <el-select v-model="form.region" placeholder="请选择任务状态">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="obj.dynamicname"
                  :value="obj.dynamicid"
                  v-for="obj in dynamiclist"
                  :key="obj.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search" icon="el-icon-search" :disabled="disabledFalg">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 任务列表 -->
    <el-card shadow="always" class="card" style="margin-top: 20px">
      <div style="margin-bottom: 20px">
        <el-button type="primary" @click="showexternalEquityadd = true" plain icon="el-icon-plus">添加</el-button>
        <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button>
      </div>
      <div class="Selectnum">
        已选中 <span style="color: #5479ff">{{ multipleSelection.length }} </span>条信息
      </div>
      <el-table
        :data="tableData"
        ref="multipleTable"
        :cell-style="{ 'text-align': 'center' }"
        height="430"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="handleClickTableRow"
        :header-cell-style="{ background: '#F7F9FC' }"
      >
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column prop="customerName" label="客户姓名" align="center"> </el-table-column>
        <el-table-column prop="statusName" label="任务进度" align="center"> </el-table-column>
        <el-table-column prop="tel1" label="客户电话" align="center"> </el-table-column>
        <el-table-column prop="applyTime" label="申请时间" align="center"> </el-table-column>
        <el-table-column prop="rightsName" label="权益名称" align="center"> </el-table-column>
        <el-table-column prop="total" label="剩余次数" align="center"> </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="addNew(scope.row, 2)" type="text" size="small" class="el_btn">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[7, 14, 21, 28]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
      <!-- </div> -->
    </el-card>
    <!-- 详情弹框 -->
    <externalEquityDialog
      @closeMailTask="closeMailTask"
      v-if="showMailDialog"
      :dates="addNewdata"
    ></externalEquityDialog>
    <!-- 添加弹出框 -->
    <el-dialog :visible.sync="showexternalEquityadd" width="60%" title="添加外部权益">
      <div class="main" style="padding: 0px 3%">
        <el-card class="Equity_main_top">
          <el-row style="text-align: center; padding-bottom: 30px">
            <el-autocomplete
              v-model="inputsearch"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入客户姓名/电话"
              @select="handleSelect"
            ></el-autocomplete>
          </el-row>
          <el-row :gutter="20" style="display: flex; justify-content: space-around; align-items: center">
            <el-col :span="8"> 姓名：{{ userlist.customerName ? userlist.customerName : '' }} </el-col>
            <el-col :span="8"> 电话：{{ userlist.tel ? userlist.tel : '' }} </el-col>
            <el-col :span="8"> 产品类别：{{ form.status }} </el-col>
          </el-row>
        </el-card>
        <el-card class="Equity_main_bottom">
          <el-col :span="8">
            选择权益：<el-select v-model="form.selectstatus" placeholder="请选择权益" style="width: 65%">
              <el-option
                :label="objs.dictionaryName"
                :value="objs.rightsId"
                v-for="objs in selectlist"
                :key="objs.dictionaryName"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            申请时间：
            <el-date-picker
              v-model="valuetime"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 65%"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            扣减次数：<el-select v-model="form.deduct" placeholder="请选择产品类别" style="width: 65%" disabled>
              <el-option label="1" value="1"></el-option>
              <!-- <el-option label="2" value="2"></el-option> -->
            </el-select>
          </el-col>
        </el-card>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showexternalEquityadd = false">取 消</el-button>
        <el-button type="primary" @click="sureCommit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import externalEquityDialog from '@/views/appointmentManagement/components/externalEquityDialog'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      form: {
        status: '',
        customerPhone: '',
        customerName: '',
        date1: '',
        region: '',
        selectstatus: '',
        deduct: 1,
      },
      tableData: [],
      multipleSelection: [],
      // searchlist: [],
      showMailDialog: false,
      currentPage4: 1,
      total: 0,
      addNewdata: '',
      loading: true,
      showexternalEquityadd: false,
      valuetime: '',
      pagesize: 7,
      dynamiclist: [],
      inputsearch: '',
      userlist: '',
      // restaurants: [],
      // state: '',
      // timeout: null,
      selectlist: [],
      disabledFalg: false,
    }
  },
  components: { externalEquityDialog },
  created() {
    this.loaddata('', this.pagesize, this.currentPage4)
    // 查找数据
    postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '05' }).then(res => {
      res.map(obj => {
        this.dynamiclist.push({ dynamicid: obj.dictionaryId, dynamicname: obj.dictionaryName })
      })
    })
  },

  methods: {
    // 搜索
    querySearchAsync(queryString, cb) {
      let data = {
        customerName: queryString,
      }
      postRequestAnthor('/rightoutside/queryCustomer', data).then(res => {
        for (let i = 0; i < res.length; i++) {
          res[i].value = res[i].customerName
        }
        cb(res)
      })
    },
    handleSelect(item) {
      // console.log(item)
      this.userlist = item
      this.form.status = item.customerProduct[0] ? item.customerProduct[0].productName : ''
      this.selectlist = item.customerProduct[0] ? item.customerProduct[0].productRights : []
      this.form.selectstatus = this.selectlist[0] ? this.selectlist[0].rightsId : ''
      // console.log(this.selectlist[0]);
    },
    // 获取页面数据方法
    loaddata(status, pagesize, pageNo) {
      postRequestAnthor('/rightoutside/queryRightsOutsidePageList', {
        status: status,
        samplingType: ['1'],
        tasktypeCode: '1',
        pageSize: pagesize,
        pageNo: pageNo,
      })
        .then(res => {
          console.log(res)
          this.tableData = res.data
          this.total = res.count
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 每页数据条数
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.loaddata(this.form.region ? this.form.region : '', this.pagesize, 1)
    },
    // 当前页
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage4 = val
      this.loaddata(this.form.region ? this.form.region : '', this.pagesize, val)
    },

    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => {
        return item.id
      })
    },
    // 确认添加
    sureCommit() {
      if (this.userlist.length != '') {
        this.selectlist.map(obj => {
          if (obj.rightsId == this.form.selectstatus) {
            return (this.selectlistname = obj.dictionaryName)
          }
        })
        this.userlist.customerProduct.map(objs => {
          console.log(objs)
          if (objs.productName == this.form.status) {
            this.statusid = objs.productId
            this.customerProductId = objs.customerProductId
          }
        })
        if (this.userlist != '') {
          if (this.valuetime == '') {
            this.$message({
              type: 'warning',
              message: '请选择申请时间',
            })
          } else {
            postRequestAnthor('/rightoutside/addsave', {
              customerId: this.userlist.customerId,
              userId: this.userlist.userId,
              customerProductId: this.customerProductId,
              productId: this.statusid,
              rightsId: this.form.selectstatus,
              rightsName: this.selectlistname,
              applyTime: this.valuetime,
              reduceNum: 1,
              createBy: 1,
            }).then(res => {
              this.loaddata('', this.pagesize, this.currentPage4)
              this.$message({
                message: '修改成功消息',
                type: 'success',
              })
              this.showexternalEquityadd = false
              this.userlist = ''
              console.log(res)
            })
          }
        }
      } else {
        this.showexternalEquityadd = false
      }
    },
    // 查询
    search() {
      // console.log(
      //   this.form.customerName,
      //   this.form.customerPhone,
      //   this.form.date1,
      //   this.form.region,
      //   this.pagesize,
      //   this.currentPage4,
      // )
      this.loading = true
      this.disabledFalg = true
      setTimeout(() => {
        this.disabledFalg = false
      }, 2000)
      postRequestAnthor('/rightoutside/queryRightsOutsidePageList', {
        customerName: this.form.customerName,
        customerTel: this.form.customerPhone,
        applyDate: this.form.date1,
        rightsId: this.form.region,
        pageSize: '7',
        pageNo: '1',
      })
        .then(res => {
          this.loading = false
          this.tableData = res.data
          this.total = res.count
          console.log(res)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 操作详情
    addNew(row) {
      this.showMailDialog = true
      this.addNewdata = row
    },
    closeMailTask() {
      this.showMailDialog = false
    },
    // 删除
    handleDelete() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            postRequestAnthor('/rightoutside/deletes', { ids: this.multipleSelection }).then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.loaddata('', this.pagesize, this.currentPage4)
              console.log(res)
            })
          })
          .catch(() => {
            console.log('删除')
            this.$message({
              type: 'warning',
              message: '已取消删除',
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.block_page {
  margin-top: 20px;
}
.el-input,
.el-select {
  width: 80%;
}
.Equity_main_top {
  padding: 10px 4%;
  background-color: #fff;
}
.Equity_main_bottom {
  padding: 20px 4% 40px;
  margin-top: 15px;
  background-color: #fff;
}
.dialog-footer {
  text-align: center;
  margin: -10px 0 20px;
  // display: flex;
  // padding: 0px 30% 20px;
  // justify-content: space-around;
}
.Selectnum {
  padding: 6px 30px;
  border: 1px solid #bfcbf4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
.searchFrom > .el-form-item {
  margin-right: 0px !important;
}
</style>
